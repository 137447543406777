
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.1172 2.13776C31.2589 2.96153 31.3325 3.80623 31.3325 4.66681C31.3325 11.1437 27.1633 16.7218 21.167 19.239C21.4668 20.0973 21.6297 21.0198 21.6297 21.9803C21.6297 23.8122 21.0371 25.5057 20.033 26.8795C21.1997 27.7236 21.9588 29.0965 21.9588 30.6466C21.9588 33.2128 19.8785 35.2931 17.3124 35.2931C14.7462 35.2931 12.6659 33.2128 12.6659 30.6466C12.6659 30.519 12.671 30.3925 12.6811 30.2674C8.3888 29.9419 5.00734 26.356 5.00734 21.9803C5.00734 20.8175 5.24614 19.7105 5.67737 18.7056C4.81497 18.2691 3.99717 17.7668 3.23188 17.2062M31.1172 2.13776C29.4668 1.78549 27.7546 1.6001 25.9991 1.6001C15.6237 1.6001 6.76085 8.07592 3.23188 17.2062M31.1172 2.13776C42.1357 4.48963 50.3991 14.2799 50.3991 26.0001C50.3991 28.296 50.082 30.5178 49.4893 32.6241M3.23188 17.2062C2.17736 19.9344 1.59912 22.8997 1.59912 26.0001C1.59912 39.4758 12.5234 50.4001 25.9991 50.4001C28.295 50.4001 30.5168 50.083 32.6232 49.4902M41.9991 38.0001L42.0002 43.3332H46.0002M49.4893 32.6241C47.4364 30.982 44.8324 30.0001 41.9991 30.0001C35.3717 30.0001 29.9991 35.3727 29.9991 42.0001C29.9991 44.8334 30.981 47.4374 32.6232 49.4902M49.4893 32.6241C52.2383 34.8231 53.9991 38.206 53.9991 42.0001C53.9991 48.6275 48.6265 54.0001 41.9991 54.0001C38.205 54.0001 34.8221 52.2393 32.6232 49.4902" stroke="#1D2433" stroke-opacity="0.65" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  </template>

  <script>
  export default {
    name: 'EarthGlobeTimezone',
    inheritAttrs: true,
  }
  </script>
